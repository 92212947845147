import { useEffect } from "react";

// hack until we update docs to nextra v4
function ExpandGettingStarted() {
  useEffect(() => {
    const interval = setInterval(() => {
      // Adjust the query selector as needed
      const triggers = Array.from(document.querySelectorAll("button"));
      const trigger = triggers.find(
        (btn) => btn.textContent?.trim() === "Getting Started"
      );
      if (trigger) {
        // Optionally check for aria-expanded if available
        if (trigger.getAttribute("aria-expanded") !== "true") {
          trigger.click();
        }
        clearInterval(interval);
      }
    }, 100);
    return () => clearInterval(interval);
  }, []);

  return null;
}

export default ExpandGettingStarted;
